import React from "react";
import { notifyManager, useQueryClient } from "react-query";

/**
 * Get the state of the API connection.
 *
 * Returns true if a query or a mutation is pending.
 */
export default function useIsLoading(): boolean {
  const client = useQueryClient();

  const isFetching = React.useCallback(
    () =>
      client.isFetching({
        predicate: (query: any) => !query.options.meta?.noLoader
      }) > 0,
    [client]
  );

  const isMutating = React.useCallback(
    () =>
      client.isMutating({
        predicate: (mutation: any) => {
          return !mutation.options.meta?.noLoader;
        }
      }) > 0,
    [client]
  );

  const mountedRef = React.useRef(false);
  const isFetchingRef = React.useRef(isFetching());
  const isMutatingRef = React.useRef(isMutating());

  const [isLoading, setIsLoading] = React.useState<boolean>(isFetchingRef.current || isMutatingRef.current);

  React.useEffect(() => {
    mountedRef.current = true;

    const unsubscribeQueryCache = client.getQueryCache().subscribe(
      notifyManager.batchCalls(() => {
        if (mountedRef.current) {
          isFetchingRef.current = isFetching();
          setIsLoading(isFetchingRef.current || isMutatingRef.current);
        }
      })
    );

    const unsubscribeMutationCache = client.getMutationCache().subscribe(
      notifyManager.batchCalls(() => {
        if (mountedRef.current) {
          isMutatingRef.current = isMutating();
          setIsLoading(isFetchingRef.current || isMutatingRef.current);
        }
      })
    );

    return () => {
      mountedRef.current = false;
      unsubscribeQueryCache();
      unsubscribeMutationCache();
    };
  }, [client, isFetching, isMutating]);

  return isLoading;
}
