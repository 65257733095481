// React 18 changes -- start
import App from "./App";
import "assets/styles/print.scss";
import { GlobalProviders } from "context/global";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const container = document.getElementById("root");
const root = createRoot(container!);

if (process.env.REACT_APP_SENTRY_KEY) {
  Sentry.init({
    release: process.env.REACT_APP_VERSION || "default-release",
    environment: process.env.REACT_APP_CUSTOM_ENV,
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new BrowserTracing()],
    // tracesSampleRate 1.0 means to capture 100% of transactions
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1)
  });
  Sentry.setTag("domain", `${window.location.origin}`);
}

root.render(
  <GlobalProviders>
    <App />
  </GlobalProviders>
);

// React 18 changes -- end

// React 17 changes -- start

// import "./index.scss";
// import App from "./App";
// import React from "react";
// import ReactDOM from "react-dom";
// import reportWebVitals from "./reportWebVitals";
// import { GlobalProviders } from "context/global";
// import "assets/styles/print.scss";

// ReactDOM.render(
//   <React.StrictMode>
//     <GlobalProviders>
//       <App />
//     </GlobalProviders>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// React 17 changes -- end
