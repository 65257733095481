import LocalStorage from "./LocalStorage";
import { LocalStorageKey } from "../enums";
import { AnyObject } from "../../../global";

export class JsonStorage {
  static getData(key: LocalStorageKey) {
    try {
      const data = LocalStorage.getItem(key) || "{}";
      return this.convertStringToObj(data);
    } catch (e) {
      console.error(e);
    }
  }

  static setData(key: LocalStorageKey, data: AnyObject) {
    try {
      const value = this.convertObjToString(data);
      LocalStorage.setItem(key, value);
    } catch (e) {
      console.error(e);
    }
  }

  private static convertObjToString(obj: AnyObject) {
    return JSON.stringify(obj);
  }

  private static convertStringToObj(str: string) {
    return JSON.parse(str);
  }
}
