import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import { DebugMode } from "shared/debug";
import { toastConfig } from "shared/utils/toastNotification";
import { FullPageLoaderForReactQuery } from "../shared/components/loader";

const queryConfig = {
  shared: {
    suspense: false
  },
  queries: {
    retry: 0,
    useErrorBoundary: false,
    refetchOnWindowFocus: false
  }
};

const queryClient = new QueryClient({
  defaultOptions: queryConfig
});

function GlobalProviders({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <FullPageLoaderForReactQuery />
      <ToastContainer {...toastConfig} />
      {children}
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <DebugMode />
    </QueryClientProvider>
  );
}

export { GlobalProviders };
