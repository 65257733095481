import { Route } from "shared/enums/Redirect";
import { MyExpRoute } from "apps/myExperience/routes/types";
import DefaultOrgHeroImage from "assets/img/default-product-img.png";
import { ConsoleRouteMap } from "apps/console/routes/routes";
import { MimeTypeEnum } from "./enums";

export const MAX_DEFAULT_TEXT_FIELD_LENGTH = 150;
export const MIN_DEFAULT_TEXTAREA_LENGTH = 20;
export const MAX_DEFAULT_TEXTAREA_LENGTH = 500;
export const MAX_DEFAULT_EMAIL_LENGTH = 150;
export const MAX_DEFAULT_URL_LENGTH = 150;
export const MAX_DEFAULT_NUMBER_VALUE = 1000000000;
export const MAX_DEFAULT_TICKET_DISPLAY_ORDER = 100;
export const MIN_NUMBER_VALUE = 0;
export const MAX_DEFAULT_PHONE_LENGTH = 16;
export const MIN_DEFAULT_PHONE_LENGTH = 7;
export const MAX_EMAIL_LENGTH = 200;
export const MAX_PHONE_LENGTH = 20;
export const MAX_TEXTAREA_LENGTH = 5000;
export const MAX_TEXT_FIELD_LENGTH = 200;
export const MAX_URL_LENGTH = 500;
export const MAX_MULTISELECT_VALUE = 500;
export const MAX_MULTI_FILE_VALUE = 10;
export const MAX_MULTI_IMAGE_VALUE = 10;
export const MAX_TAGS_VALUE = 50;
export const DEFAULT_MAX_ALLOWED_EMAIL_IN_EMAIL_LIST = 100;
export const MAX_FIELD_OPTION_LENGTH = 250;

export const MAX_NAME_LENGTH = 50;
export const MAX_JOB_TITLE_LENGTH = 100;
export const TOAST_NOTIFICATION_AUTO_CLOSE_TIME = 5000;
export const MAX_REG_HEADER_LOGO_MAX_SIZE = 50;
export const MAX_MY_EXP_IMG_MAX_SIZE = 2048;
export const MAX_TICKET_PAGE_IMAGE_SIZE = 50;
export const MAX_FAVICON_LOGO_MAX_SIZE = 20;
export const MAX_EVENT_SERIES_NAME_LENGTH = 100;
export const MAX_DEFAULT_IMAGE_SIZE_KB = 50;
export const MAX_REIMBURSEMENT_RECEIPT_UPLOAD_SIZE_KB = 5 * 1024;
export const MAX_DEFAULT_ASSET_UPLOAD_SIZE_KB = 2 * 1024;
export const MAX_DEFAULT_MULTI_FILE_UPLOAD_SIZE_KB = 5 * 1024;
export const DEFAULT_IMAGE_COMPRESS_THRESHOLD_SIZE_KB = 2 * 1024;
export const MIN_IMAGE_COMPRESS_THRESHOLD_SIZE_KB = 1 * 1024;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 20;
export const MIN_COUPON_CODE_LENGTH = 5;
export const MAX_COUPON_CODE_LENGTH = 50;
export const MAX_SOCIAL_TEMPLATE_IMG_MAX_SIZE = 400;
export const MAX_FIELD_LABEL_LENGTH = 500;
export const MAX_REGISTRATION_FORM_NAME = 100;
export const MAX_REGISTRATION_FORM_TITLE = 100;
export const MAX_HTML_FIELD_LENGTH = 2000;
export const MAX_DISPLAY_NOTE_FIELD_LENGTH = 5000;
export const MAX_FAQ_CONTENT_FIELD_LENGTH = 50000;
export const MAX_ALLOWED_CC_BCC_EMAILS = 3;
export const MAX_ALLOWED_ADMIN_NOTIFICATION_EMAILS = 3;
export const MAX_TEMPLATE_NAME = 20;
export const MAX_PROGRAM_NAME = 30;
export const MAX_BULK_RATE_QTY = 999;
export const MAX_REGISTRATION_KEY_LENGTH = 50;
export const MAX_DIVISION_LENGTH = 100;
export const MAX_REGISTRATION_INFO_LENGTH = 100;
export const MAX_REGISTRATION_NAME_LENGTH = 100;
export const MAX_PARTICIPANT_TYPE_DISPLAY_LABEL_LENGTH = 100;
export const MAX_EMAIL_TEMPLATE_SUBJECT_LENGTH = 1000;

export const MIN_PROGRAM_PARAMETER = 1;
export const MAX_RECOMMENDED_SELECTION_MULTIPLIER = 20;
export const MAX_MARK_AS_COMPLETED_MULTIPLIER = 20;
export const MAX_SELLER_RECOMMENDED_SELECTION_MULTIPLIER = 20;
export const MAX_MEETING_COUNT = 100;
export const MAX_MEETING_COUNT_TABLETALK = 20;
export const MAX_HOSTED_BUYER_RECOMMENDED_OPT_IN_COUNT = 100;
export const MAX_HOSTED_BUYER_MAX_MEETING_COUNT = 100;
export const MIN_HOSTED_BUYER_MIN_TIME_SLOT_COUNT = 1;
export const MAX_HOSTED_BUYER_MIN_TIME_SLOT_COUNT = 100;
export const MAX_MIN_TIMESLOT_COUNT = 100;
export const MAX_EMAIL_INTRODUCTION_COUNT = 100;
export const MAX_PROGRAM_LOCATION_LENGTH = 150;
export const MIN_DEFAULT_HTML_FIELD_LENGTH = 20;
export const MIN_UNGROUPED_PAGE_LIST_SIZE_FOR_SELECTION = 200;
export const MAX_UNGROUPED_PAGE_LIST_SIZE_FOR_SELECTION = 5000;
export const MIN_GROUPED_PAGE_LIST_SIZE_FOR_SELECTION = 100;
export const MAX_GROUPED_PAGE_LIST_SIZE_FOR_SELECTION = 5000;
export const MIN_SPONSOR_COUNT_FOR_SELECTION = 0;
export const MAX_SPONSOR_COUNT_FOR_SELECTION = 5;
export const MAX_PAYMENT_CONFIG_NOTE_LENGTH = 200;
export const MAX_STRIPE_API_BASE_LENGTH = 255;
export const MAX_DISPLAY_LABEL_LENGTH = 200;
export const MIN_IDENTIFIER_LENGTH = 5;
export const MAX_IDENTIFIER_LENGTH = 255;
export const MAX_AGENDA_ITEM_TITLE = 200;
export const MAX_AGENDA_ITEM_DESCRIPTION = 2000;

export const MAX_TIME_SESSION_NAME_LENGTH = 100;
export const MAX_TIME_SESSION_DISPLAY_LABEL_LENGTH = 100;
export const MAX_TIME_SLOT_NAME_LENGTH = 100;
export const DEFAULT_BASE_DATE = new Date("01-Jan-1970");
export const MAX_LOCATION_NAME_LENGTH = 100;
export const MAX_LOCATION_DESCRIPTION_LENGTH = 500;
export const MAX_PARTICIPATION_ROLE_NAME_LENGTH = 100;

export const MAX_NOTE_LENGTH_IN_MEETING = 150;
export const MIN_NOTE_LENGTH_IN_MEETING = 20;

export const MAX_TABLE_COUNT = 10000;

export const MAX_FEEDBACK_DISPLAY_LABEL_LENGTH = 100;
export const MAX_FEEDBACK_DISPLAY_ORDER = 100;
export const MAX_FEEDBACK_FORM_NAME_LENGTH = 100;

export const MIN_ASSOCIATE_COUNT_IN_MEETING = 1;
export const MAX_ASSOCIATE_COUNT_IN_MEETING = 5;
export const MIN_ADD_ASSOCIATE_BEFORE_MEETING_THRESHOLD = 5;
export const MAX_ADD_ASSOCIATE_BEFORE_MEETING_THRESHOLD = 300;

/**
 * @todo replace value for DEFAULT_HELPDESK_EMAIL with appropriate one
 */
export const DEBOUNCE_TIME = 300;
export const DEFAULT_MAX_RESULTS_COUNT = 10;
export const DEFAULT_MAX_RESULTS_COUNT_FOR_PAGINATION_SEARCH = 100;
export const TABLE_MIN_WIDTH = 50;
export const TABLE_WIDTH = 150;
export const TABLE_MAX_WIDTH = 400;
export const TABLE_ROW_DEFAULT_HEIGHT = 35;
export const API_EXCEPTION_STATUS_CODE = 422;
export const API_SESSION_EXPIRE_STATUS_CODE = 401;
export const API_FORBIDDEN_403_DEFAULT_ERR_MSG_ADMIN = "You are not authorized to perform this action.";
export const API_FORBIDDEN_STATUS_CODE = 403;
export const DEFAULT_ERR_MSG_ADMIN = "Some error occurred";
export const SESSION_EXPIRE_TOAST_ID = "sessionExpiredToast";
export const CREATE_ID = "create";
export const MD5_SUFFIX = "Md5";
// TODO: Need to replace mime type with MimeTypeEnum Enum
export const PDF_MIME_TYPE = "application/pdf";
export const ACCEPTED_IMAGE_MIME_TYPE = `${MimeTypeEnum.IMAGE_PNG_MIME_TYPE}, ${MimeTypeEnum.IMAGE_JPEG_MIME_TYPE}, ${MimeTypeEnum.IMAGE_JPG_MIME_TYPE}, ${MimeTypeEnum.IMAGE_SVG_MIME_TYPE}`;
export const ACCEPTED_FILE_MIME_TYPE = MimeTypeEnum.PDF_MIME_TYPE;
export const ACCEPTED_IMAGE_AND_PDF_MIME_TYPE = `${ACCEPTED_IMAGE_MIME_TYPE}, ${ACCEPTED_FILE_MIME_TYPE}`;
export const PDF_FILE_EXTENSION = ".pdf";
export const FAVICON_MIME_TYPE = "image/vnd.microsoft.icon, image/x-icon";
export const ALL_FILTER_KEY = "ALL";
export const ADMIN_CONSOLE_TITLE = "Admin Console";
export const ADMIN_CONSOLE_FAVICON_URL = "assets/img/fav-ico.ico";
export const ADMIN_CONSOLE_CARDS_LENGTH = 3;
export const PERSONATECH_URL = "https://personatech.com";
export const MULTIPLE_VALUES_IN_URL_SEPARATOR = "|";
export const DISPLAY_ORDER_KEY = "displayOrder";
export const DEFAULT_INITIAL_VALUE_ARRAY = [];
export const DEFAULT_INITIAL_VALUE_CHECKBOX = false;
export const DEFAULT_LOCALE = "en-US";
export const DIGITS_AFTER_DECIMAL = 2;
export const PERCENTAGE_SYMBOL = " %";
export const SMALL_SCREEN_WIDTH = 1440;
export const CARDS_LENGTH_SMALL_SCREEN = 3;
export const CARDS_LENGTH_LARGE_SCREEN = 4;

export const MIN_TABLE_ROW_TO_SHOW_SEARCH_IN_MY_EXPERIENCE = 5;
export const SINGLE_SELECT_OPTION_COUNT_FOR_SEARCH = 10;
export const DEFAULT_MIN_MEETING_PURCHASE_COUNT = 1;

export const REGISTRATION_DASHBOARD_CHART_TEXT_COLOR = "#18407b";

export const MIN_TRANSACTION_AMOUNT = 1;
export const REGISTER_PARTICIPANT_CHART_DISPLAY_ITEM_COUNT = 10;

export const TABLETALK_TOPIC_PARTICIPANT_TYPES_DISPLAYED_COUNT = 5;
export const ORGANIZATION_SEARCH_RESULT_COUNT = 10;
export const ORGANIZATION_SEARCH_MIN_CHARACTER_COUNT = 2;
export const FORM_SCROLL_TO_BOTTOM_HEIGHT_OFFSET = 10;

/** Using this to determine whether actions are coming in 2 line */
export const TABLE_OPTION_ACTION_ITEM_HEIGHT = 32;
export const REIMBURSEMENT_RECEIPT_DESCRIPTION_MAX_SIZE = 255;
export const REIMBURSEMENT_RECEIPT_DESCRIPTION_MIN_SIZE = 3;

export const ORGANIZATION_NAME_RESET_DELAY_TIME = 100;

export const ASSOCIATE_COUNT_TO_SHOW_UPFRONT = 2;
export const RESEND_INVITE_SUCCESS_TICK_TIME = 5000;
export const ASSOCIATE_BUTTON_TOP_POSITION_OFFSET = 200;

export enum HtmlIdentifier {
  PAYMENT_CONTAINER = "payment_container"
}

export const DUMMY_URL = "https://dummy.url";
export const DUMMY_SINGLE_IMAGE_URL = DefaultOrgHeroImage;

/** Mobile Configuration */
export const MAX_NOTIFICATION_TITLE = 50;
export const MAX_NOTIFICATION_MESSAGE = 100;
export const MAX_NOTIFICATION_BODY = 1000;
export const NOTIFICATION_BODY_HEIGHT = 240;
export const MAX_FILE_SIZE_FOR_KEY_FILE = 1024;
export const ACCEPTED_FILE_TYPE_FOR_GOOGLE_SERVICES = "application/json, .json";
export const ACCEPTED_FILE_TYPE_FOR_IOS_PUSH_KEY = "application/pkcs8, .p8";

// start - Do not change the values of these constants
export const CONSENT_COOKIE_NAME = "consentCookie";
export const CONSENT_COOKIE_VERSION = 2;
// end - Do not change the values of these constants

export const UNLIMITED_QTY = -1;
export const MAX_TAG_LENGTH = 50;
export const MIN_TAG_LENGTH = 2;
export const TAG_INPUT_FIELD_NAME = "_tagInput";

export const MIN_PRODUCT_COUNT_MINIMUM_VALUE = 0;
export const MIN_PRODUCT_COUNT_MAXIMUM_VALUE = 50;
export const MAX_PRODUCT_COUNT_MINIMUM_VALUE = 1;
export const MAX_PRODUCT_COUNT_MAXIMUM_VALUE = 50;
export const DEFAULT_INITIAL_VALUE_QUERY_BUILDER = { rules: [] };

export const MAX_ALLOWED_FIELDS_IN_SECTION_ONE_FOR_TEMPLATE_TRADE_PROGRAM_ORGANIZATION = 1;
export const MAX_ALLOWED_FIELDS_IN_SECTION_TWO_FOR_TEMPLATE_TRADE_PROGRAM_ORGANIZATION = 2;

export const MIN_MEETING_REP_AVAILABILITY_PER_TIMESLOT = 1;
export const MAX_MEETING_REP_AVAILABILITY_PER_TIMESLOT = 100;
export const DEFAULT_VALUE_FOR_EMPTY_FIELD = "--";
export const DEFAULT_DISPLAY_VALUE_CHARACTER_FOR_PASSWORD = "•";
export const DEFAULT_LENGTH_FOR_DISPLAY_VALUE_FOR_PASSWORD = 8;

export const ASPECT_RATIO_ERROR_MARGIN = 0.1;
export const MAX_FILE_TO_UPLOAD = 1;
export const MAX_FILE_NAME_LENGTH = 50;

// Associate Program
export const ACTIVITY_TEXT_FIELD_MIN_LENGTH = 3;
export const ACTIVITY_TEXT_FIELD_MAX_LENGTH = 100;
export const ACTIVITY_BANNER_IMAGE_MAX_SIZE_KB = 2 * 1024;
export const ACTIVITY_TEXTAREA_MAX_LENGTH = 1000;
export const ACTIVITY_ASSETS_MAX_LIMIT = 5;
export const ASSOCIATION_MIN_ATTENDANCE_MIN_LIMIT = 1;
export const ASSOCIATION_MIN_ATTENDANCE_MAX_LIMIT = 998;
export const ASSOCIATION_MAX_ATTENDANCE_MIN_LIMIT = 2;
export const ASSOCIATION_MAX_ATTENDANCE_MAX_LIMIT = 999;
export const ASSOCIATION_MAX_ATTENDANCE_UNLIMITED = -1;

// Event Location & Sectors
export const EVENT_LOCATION_DESCRIPTION_MAX_LENGTH = 200;
export const EVENT_LOCATION_ADDITIONAL_LABEL_MAX_LENGTH = 200;
export const EVENT_LOCATION_OR_SECTOR_NAME_MAX_LENGTH = 100;

// Event Sector
export const EVENT_SECTOR_NAME_MAX_LENGTH = 200;

export const EVENT_EXTERNAL_REFERENCE_ID_MIN_LENGTH = 2;
export const EVENT_EXTERNAL_REFERENCE_ID_MAX_LENGTH = 200;

// Read More
export const MY_EXP_PRODUCT_DESC_HEIGHT = 176;

export const INITIAL_HANDPICK_FETCH_TYPE = "ALL";

export const TRUE_STRING = "true";
export const FALSE_STRING = "false";
export const NOTE_MAX_CHAR_LIMIT = 1000;
export const CONTACT_NOTE_MAX_CHAR_LIMIT = 200;
export const MIN_ADMIN_ACTION_ALLOWED_IN_MINUTES = 15;
export const MAX_ADMIN_ACTION_ALLOWED_IN_MINUTES = 300;

/* Overflow Hidden class */
export const SCROLL_DISABLED = "scroll_disabled";

/* Scroll shadow effect container height */
export const ACTIVITY_SLIDER_BODY_HEIGHT = "calc(100vh - 50px)";

// Reimbursement constants
export const MAX_NOTE_FOR_PARTICIPANT_LENGTH = 200;
export const MIN_FOR_MAX_REIMBURSEMENT_RECEIPTS = 1;
export const MAX_FOR_MAX_REIMBURSEMENT_RECEIPTS = 9;

export const DEFAULT_NOTIFICATION_TIME_BUFFER_IN_MINUTE = 10;

export const FAKE_LOADER_TIME_OUT = 550; // in ms

export const DEFAULT_TEXTAREA_NO_OF_ROWS = 4;
export const MAX_AGENDA_ITEM_NOTE_LENGTH = 200;
export const MIN_QUANTITY_FOR_BULK_TICKETS = 1;
export const ALLOWED_DECISION_OPERAND_LEVEL = 2;
export const DEFAULT_NAME_INITIALS_CHAR_COUNT = 2;
export const MAX_ADDITIONAL_CHARGE_NOTE_LENGTH = 200;
export const MIN_AGENDA_TIME_SLOT_SCHEDULING_ORDER = 1;
export const MAX_AGENDA_TIME_SLOT_SCHEDULING_ORDER = 99;
export const DEFAULT_MEETING_REP_AVAILABILITY_COL_COUNT = 2;

// registration config -> inventory -> settings
export const ENTITY_TAX_LABEL_MAX_CHAR = 20;
export const QUANTITY_BREAK_MAX_LIMIT_MIN_VALUE = 10;
export const ADMIN_ASSIGNMENT_MAX_LIMIT_MIN_VALUE = 1;
export const QUANTITY_BREAK_MAX_LIMIT_MAX_VALUE = 999;

// selectable components
export const EXCESS_FILTERS_HEIGHT_THRESHOLD = 10;
export const SELECTABLE_LIST_START_PAGE_NUMBER = 1;
export const STATIC_RENDER_LONG_LIST_MAX_LIMIT = 10;
export const SELECTABLE_LIST_FILTERS_SHOW_MORE_LESS_THRESHOLD = 3;
export const MULTI_SELECT_ITEMS_TO_SHOW_UPFRONT_IN_SELECTABLE_CARD = 7;

// associate timeslots to activity
export const ASSOCIATION_ATTENDANCE_LIMIT_MIN_LIMIT = 1;
export const ASSOCIATION_ATTENDANCE_LIMIT_MAX_LIMIT = 999;

// myexp selection
export const LONG_LIST_SIZE = 10;
export const MINIMUM_SELECTION_REASON = 20;
export const MAXIMUM_SELECTION_REASON = 160;
export const SELECTION_STARTING_PAGE_NUMBER = 1;
export const DEFAULT_SEARCH_MIN_CHARACTER_COUNT = 2;
export const PRE_DEFINED_FILTER_ID = "preDefinedFilterId";
export const MULTI_SELECT_ITEMS_TO_SHOW_UPFRONT_IN_CARD = 7;
export const REFETCH_INTERVAL_FOR_SELECTION_META_DATA = 4 * 60 * 1000;
export const MAX_GTM_FIELD_SIZE = 14;

export const REPORTS_CARD_COLLAPSED_COUNT = 3;

export const PERSONATECH_COOKIE_POLICY_URL = "https://personatech.com/cookiesPolicy";

// Feature Control - following routes/ redirect routes will be allowed on Mobile
export const FEATURE_CONTROL_WHITELISTED_ROUTES_FOR_MOBILE = [
  MyExpRoute.FAQ.path,
  MyExpRoute.AGENDA_PARTICIPATION_INVITE.path,
  Route.AGENDA_PARTICIPATION_INVITE,
  ConsoleRouteMap.IFRAME.path
];

export const LOGO_ONLY_HEADER_ROUTES_FOR_MOBILE = [MyExpRoute.AGENDA_PARTICIPATION_INVITE.path];

export const MAX_CUSTOM_DATA_LENGTH = 500;

export const DATE_RANGE_JOIN_BY = " - ";
export const MAX_SMS_LENGTH = 300;
export const MAX_SMS_MESSAGE_SERVICE_ID_LENGTH = 50;
export const YEAR_SELECTOR_ID = "yearSelector";
export const MAX_SMS_APP_ID_LENGTH = 50;
export const MAX_SMS_AUTH_TOKEN_LENGTH = 100;
export const MIN_EMAIL_DOMAIN_LIST_NAME_LENGTH = 2;
export const MAX_EMAIL_DOMAIN_LIST_NAME_LENGTH = 100;
export const MAX_EMAIL_DOMAIN_VALIDATION_ERROR_MSG_LENGTH = 200;

export const MATERIAL_TABLE_INCREASE_VIEWPORT_TOP = 0;
export const MATERIAL_TABLE_INCREASE_VIEWPORT_BOTTOM = 200;

export const VENDOR_ENTITY_LABEL_MAX_LENGTH = 50;
export const VENDOR_URL_FIELD_MAX_LENGTH = 250;
export const VENDOR_EXTERNAL_FIELD_ID_MAX_LENGTH = 50;
export const MAX_EVENT_TRIGGER_SCRIPT_LENGTH = 1000;
export const REFETCH_INTERVAL_FOR_REPORT_TEMPLATE = 3000;
export const VENDOR_API_RATE_LIMIT_MIN_COUNT = 1;
export const VENDOR_API_RATE_LIMIT_MAX_COUNT = 100000;
export const MAX_OFFLINE_TRANSACTION_NOTE_LENGTH = 200;
