import Icon from "assets/icons";

const copies = {
  "something.broke": "We ran into an issue. Click Reload and contact us if the issue persists.",
  oops: "OOPS!",
  reload: "Reload"
};

export default function ChunkReload() {
  const reload = () => {
    window.location.href = window.location.origin + "?refresh=" + Math.random();
  };
  return (
    <div
      style={{
        position: "absolute",
        left: "0px",
        right: "0px",
        zIndex: "4",
        top: "52px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 52px)",
        padding: "0 15px"
      }}>
      <div
        style={{
          position: "relative"
        }}>
        <span
          style={{
            position: "relative",
            width: "80px",
            height: "80px",
            display: "block",
            borderRadius: "50%",
            color: "#de0606",
            margin: "0px auto 16px",
            backgroundColor: "rgba(222, 6, 6, 0.2)",
            fontFamily: "sans-serif"
          }}>
          <Icon.BrokenLink
            style={{
              position: "absolute",
              left: "0px",
              right: "0px",
              zIndex: "4",
              top: "50%",
              margin: "0px auto",
              fontSize: "30px",
              transform: "translateY(-50%)"
            }}
          />
        </span>
        <div style={{ textAlign: "center" }}>
          <b
            style={{
              lineHeight: "26px",
              fontSize: "30px",
              color: "#18407b",
              display: "block",
              position: "relative",
              fontFamily: "sans-serif"
            }}>
            {copies.oops}
          </b>
          <span
            style={{
              margin: "12px 0px",
              fontSize: "16px",
              display: "block",
              fontFamily: "sans-serif"
            }}>
            {copies?.["something.broke"]}
          </span>
          <button
            style={{
              textDecoration: "none",
              display: "inlineBlock",
              verticalAlign: "middle",
              fontFamily: "sans-serif",
              backgroundColor: "#18407b",
              border: "1px solid #18407b",
              color: "#ffffff",
              borderRadius: "4px",
              padding: "8px 12px",
              cursor: "pointer"
            }}
            onClick={reload}>
            <Icon.Reload
              style={{
                marginRight: "8px",
                verticalAlign: "inherit"
              }}
            />
            <span style={{ fontSize: "14px" }}>{copies.reload}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
