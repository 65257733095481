import React from "react";
import Loader from "./loader";
import { FullPageLoaderProps } from "./loaderTypes";
import useIsLoading from "shared/hooks/useIsLoading";

/**
 * @description Should be used at only one place for showing loader for useQuery and useMutation
 */
export function FullPageLoaderForReactQuery() {
  const isLoading = useIsLoading();

  return <FullPageLoaderWithPortal isLoading={isLoading} />;
}

/**
 * @description use this to show loader based on isLoading flag.
 */
function FullPageLoaderWithPortal(props: FullPageLoaderProps) {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (props.isLoading) {
      setLoading(props.isLoading);
      return;
    }
    const timeoutId = setTimeout(() => {
      setLoading(props.isLoading);
      clearTimeout(timeoutId);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [props.isLoading]);

  return loading ? <Loader /> : null;
}

export default FullPageLoaderWithPortal;
