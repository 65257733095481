import { useLocation, useSearchParams } from "react-router-dom";
import queryString, { ParseOptions } from "query-string";
import { useCallback, useMemo } from "react";
import { AnyObject } from "global";
import { defaultParseOptions } from "./utils";

export function useQueryParams(parseOptions: ParseOptions = defaultParseOptions) {
  const location = useLocation();

  const queryParams = useMemo(() => getQueryParams(location.search), [location.search]);

  const [, setSearchParam] = useSearchParams();

  const stringifyQueryParams = useCallback(
    (obj: AnyObject) => {
      return queryString.stringify(obj, { ...parseOptions });
    },
    [parseOptions]
  );

  const setSearchParams = useCallback(
    (obj: AnyObject) => {
      return setSearchParam(queryString.stringify(obj, { ...parseOptions }));
    },
    [parseOptions, setSearchParam]
  );

  return useMemo(() => {
    return { queryParams, stringifyQueryParams, setSearchParams };
  }, [queryParams, setSearchParams, stringifyQueryParams]);
}

export function getQueryParams(search: string = "", options: ParseOptions = defaultParseOptions) {
  return queryString.parse(search || window.location.search, {
    arrayFormat: options.arrayFormat,
    arrayFormatSeparator: options.arrayFormatSeparator,
    parseBooleans: options.parseBooleans
  });
}

export function stringifyQueryParams(params: AnyObject, options: ParseOptions = defaultParseOptions) {
  return queryString.stringify(params, { ...options });
}
