import React from "react";
import { getQueryParams } from "shared/hooks/useQueryParams/useQueryParams";
import { LocalStorageKey } from "shared/utils/enums";
import { JsonStorage } from "shared/utils/storage/JsonStorage";

function getDebugDataFromStorage() {
  return JsonStorage.getData(LocalStorageKey.DEBUG_MODE);
}

const isDebugKeyInUrl = !!getQueryParams()?.[LocalStorageKey.DEBUG_MODE];
const debugDataFromStorage = getDebugDataFromStorage();
export const isDebugMode = isDebugKeyInUrl || debugDataFromStorage.debug;

enum LogLevel {
  INFO,
  WARNING,
  ERROR,
  DEBUG
}

if (isDebugMode) {
  JsonStorage.setData(LocalStorageKey.DEBUG_MODE, {
    ...debugDataFromStorage,
    debug: true,
    level: debugDataFromStorage.level || LogLevel.INFO
  });
}

let level: LogLevel = JsonStorage.getData(LocalStorageKey.DEBUG_MODE).level;

function handleLogLevelChangeAndReloadPage(level: LogLevel) {
  JsonStorage.setData(LocalStorageKey.DEBUG_MODE, {
    ...getDebugDataFromStorage(),
    level
  });
  window.location.reload();
}

export function DebugMode() {
  const [debugModeDialog, setDebugModeDialog] = React.useState(false);
  if (isDebugMode) {
    return debugModeDialog ? (
      <div
        style={{
          bottom: 100,
          right: 0,
          position: "absolute",
          zIndex: 10000,
          width: "30%",
          height: "30%",
          border: "1px solid mistyrose",
          backgroundColor: "white",
          padding: 6
        }}>
        <h4>Debug mode</h4>
        <button
          onClick={() => {
            localStorage.removeItem(LocalStorageKey.DEBUG_MODE);
            window.location.href = window.location.origin;
          }}>
          Exit
        </button>
        <button onClick={() => setDebugModeDialog(false)}>X</button>
        <div>
          <div>
            <button
              style={{ backgroundColor: level === LogLevel.INFO ? "mistyrose" : "" }}
              onClick={() => handleLogLevelChangeAndReloadPage(LogLevel.INFO)}>
              Info
            </button>
          </div>
          <div>
            <button
              style={{ backgroundColor: level === LogLevel.WARNING ? "mistyrose" : "" }}
              onClick={() => handleLogLevelChangeAndReloadPage(LogLevel.WARNING)}>
              Warn
            </button>
          </div>
          <div>
            <button
              style={{ backgroundColor: level === LogLevel.ERROR ? "mistyrose" : "" }}
              onClick={() => handleLogLevelChangeAndReloadPage(LogLevel.ERROR)}>
              Error
            </button>
          </div>
          <div>
            <button
              style={{ backgroundColor: level === LogLevel.DEBUG ? "mistyrose" : "" }}
              onClick={() => handleLogLevelChangeAndReloadPage(LogLevel.DEBUG)}>
              Debug
            </button>
          </div>
        </div>
      </div>
    ) : (
      <button
        style={{
          bottom: 100,
          right: 0,
          position: "absolute",
          zIndex: 10000,
          color: "red"
        }}
        onClick={() => setDebugModeDialog(true)}>
        Debug
      </button>
    );
  }
  return null;
}

export const log = {
  __log: (...args: any[]) => {
    if (isDebugMode) {
      // intentional; do not remove
      console.log(...args);
    }
  },
  info: (...args: any[]) => {
    if (level >= LogLevel.INFO) {
      log.__log("%c[Info] - %s", "color:#1f6fe6", ...args);
    }
  },
  warn: (...args: any[]) => {
    if (level > LogLevel.INFO) {
      log.__log("%c[Warning] - %s", "color:#ff9800", ...args);
    }
  },
  error: (...args: any[]) => {
    if (level > LogLevel.WARNING) {
      log.__log("%c[Error] - %s", "color:red", ...args);
    }
  },
  debug: (...args: any[]) => {
    if (level > LogLevel.ERROR) {
      log.__log("%c[Debug] - %s", "color:black", ...args);
    }
  }
};
