export const MyExpRoute = {
  HOME: {
    path: "/"
  },
  MANAGE_ORG_ADMIN: {
    path: "home/organization-admins",
    create: {
      path: "create"
    },
    assign: {
      path: ":registrationParticipantId",
      screenNameKey: "admin"
    }
  },
  MANAGE_BULK_TICKET: {
    path: "home/manage-bulk-ticket"
  },
  PURCHASE_HISTORY: {
    path: "home/purchase-history"
  },
  TICKET_TRANSFER: {
    path: "home/ticket-transfer"
  },
  ADDITIONAL_CHARGE: {
    path: "home/additional-charge"
  },
  TICKET_PURCHASE: {
    path: "home/ticket-purchase"
  },
  TICKET_ASSIGN: {
    path: "home/ticket-assign"
  },
  MANAGE_PARTICIPANT: {
    path: "home/manage-participant/:programParticipantId"
  },
  UPDATE_PROFILE_PIC: { path: "home/profile-pic" },
  SOCIAL_MEDIA_SHARE: { path: "home/social-media-share" },
  MY_INFO: { path: "home/myinfo" },
  PROFILE: { path: "profile/:programParticipantId" },
  TABLETALK_PROFILE: { path: "tabletalk-profile/:programParticipantId" },
  // For Activity Admin: ASSOCIATED_PROGRAM_ACTIVITIES
  WORKFLOW: {
    path: "workflow/:programParticipantId",
    ASSOCIATED_PROGRAM_ACTIVITIES: {
      path: "activities",
      DETAILS: {
        path: ":activityId"
      },
      TIMESLOTS: { path: "timeslots/:activityId" }
    },
    ASSOCIATED_PROGRAM_SHORTLIST: {
      DETAILS: {
        path: ":activityId"
      },
      path: "activity-shortlist"
    },
    ASSOCIATED_PROGRAM_SCHEDULE: {
      DETAILS: {
        path: ":activityAgendaId"
      },
      path: "activity-schedule"
    },

    ASSOCIATED_PROGRAM_MARK_ATTENDANCE: {
      DETAILS: {
        path: ":activityAgendaId",
        invite: { path: "invite" },
        manage: { path: "manage" },
        inviteWithAgendaId: { path: "invite/:agendaId" },
        manageAgendaId: { path: "manage/:activityAgendaId" }
      },
      path: "manage-activities"
    }
  },
  // For Participant: ASSOCIATED_PROGRAM_ACTIVITY_LIST
  ASSOCIATED_PROGRAM_ACTIVITY_LIST: {
    path: "activity-list/:eventParticipantId",
    TIMESLOTS: { path: "timeslots/:activityId" }
  },
  ORGANIZATION_PROFILE: { path: "org-profile/:programOrganizationId" },
  ORGANIZATION: {
    path: "organization",
    PROFILE_LIST: {
      path: "profile-list/:orgAdminProgramParticipantId"
    },
    PROFILE: {
      path: "profile/:programParticipantId"
    },
    AGENDA_PARTICIPATION_LIST: {
      path: "agenda-participation-list/:orgAdminProgramParticipantId"
    },
    ACCEPT_MEETINGS: {
      path: "agenda-participation-list/:orgAdminProgramParticipantId/:programParticipantId"
    },
    ORG_SCHEDULE: {
      path: "schedule/:contextEntityType/:orgAdminProgramParticipantId",
      PARTICIPANT_SCHEDULE: {
        path: ":entityId",
        MEETING_DETAIL: {
          path: ":meetingId"
        },
        MEETING_DETAIL_DEFAULT: {
          path: "default"
        }
      }
    },
    FEEDBACK_LIST: {
      path: "feedback-list/:orgAdminProgramParticipantId"
    }
  },
  ORGANIZATION_SCHEDULE: {
    path: "/org-schedule/:programOrganizationId"
  },
  PARTICIPANT_SCHEDULE: {
    path: "/participant-schedule/:contextEntityType/:entityId",
    MEETING_DETAIL: {
      path: ":meetingId"
    },
    MEETING_DETAIL_DEFAULT: {
      path: "default"
    }
  },
  ACCEPT_MEETINGS: { path: "accept-meetings/:programParticipantId", screenNameKey: "acceptMeetings" },
  ASSIGN_MEETING_REP: { path: "assign-meeting-rep/:orgAdminPPIDInMeetingRepAssign", screenNameKey: "acceptMeetings" },
  FEEDBACK: { path: "feedback/:eventParticipantId" },
  REDIRECT: { path: "/redirect" },
  SELECTION: { path: "/selection/:programParticipantId" },
  EXHIBITORS_LIST: { path: "/exhibitors/:actorEntityType/:actorId/:programId" },
  REIMBURSEMENT: { path: "/reimbursement/:programParticipantId" },
  ORG_ADMIN_SELECTION: { path: "/org-admin-selection/:programParticipantId" },
  AGENDA: { path: "/agenda" },
  LOGIN: { path: "/login" },
  FORGOT_PASSWORD: { path: "/forgotPassword" },
  FAQ: { path: "/faq" },
  REGISTRATION_ELAPSED: { path: "/elapsed" },
  AGENDA_PARTICIPATION_INVITE: { path: "/agenda-participation-invite/:programParticipationId/:status" },
  CONTACTS: { path: "/contacts/:eventParticipantId" },
  MAGIC_LOGIN: { path: "magic-login" },
  PAYMENT_STATUS: { path: "/paymentStatus" }
};
