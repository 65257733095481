/**
 * Type of build
 */
export enum Environment {
  LOCAL = "LOCAL",
  LOCAL_BUILD = "LOCAL_BUILD",
  LOCAL_BUILD_LOCAL_HOST_ONLY = "LOCAL_BUILD_LOCAL_HOST_ONLY",
  QA = "QA",
  PRODUCTION = "PRODUCTION",
  STAGING = "STAGING",
  DEMO = "DEMO",
  TEST = "TEST",
  WRITING_TEST_CASES = "WRITING_TEST_CASES",
  FEATURE = "FEATURE"
}

/**
 * Type of application
 */
export enum AppType {
  CONSOLE = "CONSOLE",
  REGISTRATION = "REGISTRATION",
  MYEXPERIENCE = "MYEXPERIENCE"
}

// Backend constant for my experience
export const MY_EXPERIENCE = "MY_EXPERIENCE";

/**
 * Type of HTTP methods supported
 */
export enum HttpVerb {
  POST = "POST",
  GET = "GET",
  DELETE = "DELETE"
}

/**
 * Enum with all the keys to be used to store data in storage across our applications.
 * Convention to follow: __pt_<words_separated_by_single_underscore>
 */
export enum LocalStorageKey {
  TOKEN = "__pt_token",
  TRANSIENT_TOKEN = "__pt_transient_token",
  ENTITY_STORAGE = "__pt_entity_storage_key",
  USER_DETAIL = "__pt_user_detail",
  DISPLAY_NOTE = "__pt_display_note",
  LOCALE = "__pt_locale",
  REG_KEY = "__pt_reg_key",
  TABLE_STATE = "__pt_table_state",
  DEBUG_MODE = "__debug",
  SPONSOR_AD = "__pt__sponsor__branding",
  SELECTIONINFO = "__pt__selection_info",
  ACTIVITY_SELECTION_DISCLAIMER = "__pt__activity__selection__disclaimer"
}

export enum TableType {
  FIXED = "FIXED",
  VARIABLE = "VARIABLE",
  DYNAMIC = "DYNAMIC"
}

export enum AsyncSearchValueSetIn {
  ORG_DETAILS = "__orgDtls__"
}

/**
 * Common date format
 */
export enum DateFormat {
  DATE_ONLY = "MM/dd/yyyy" //TODO:ASF move this to i18 file
}

/**
 * @description Type of payment service we are using; for now we will be using only Braintree.
 */
export enum PaymentGatewayType {
  BRAINTREE = "BRAINTREE",
  STRIPE = "STRIPE"
}

/**
 * @description PayPal supported flow type that we can use; for now we will only use checkout flow.
 */
export enum PayPalFlowType {
  CHECKOUT = "checkout"
}

export enum AccordionStatus {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  LOCKED = "LOCKED",
  DISABLED = "DISABLED"
}

export enum SideNavItemStatus {
  LOCKED = "locked",
  COMPLETED = "completed",
  ACTIVE = "active"
}

export enum RegistrationStep {
  EMAIL_VALIDATION = "emailValidation",
  DETAILS = "details",
  PAYMENT = "payment"
}

export enum ThemeVariable {
  TEXT = "--text",
  HOVER = "--hover",
  ACTIVE = "--active",
  PRIMARY = "--primary",
  FORM = "--formPrimary",
  HEADER = "--headerColor",
  SECONDARY = "--secondary",
  PRIMARY_RGB = "--primary--rgb",
  PRIMARY_LIGHT = "--primary--light",
  PRIMARY_LIGHTER = "--primary--lighter",
  SECONDARY_RGB = "--secondary--rgb",
  SECONDARY_LIGHT = "--secondary--light"
}

export enum MediaType {
  VIDEO = "videoinput"
}

export enum SocialMediaPlatform {
  TWITTER = "twitter",
  LINKEDIN = "linkedIn"
}

export enum CRUD_TYPE {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE"
}

export enum MimeTypeEnum {
  PDF_MIME_TYPE = "application/pdf",
  IMAGE_PNG_MIME_TYPE = "image/png",
  IMAGE_JPEG_MIME_TYPE = "image/jpeg",
  IMAGE_JPG_MIME_TYPE = "image/jpg",
  IMAGE_SVG_MIME_TYPE = "image/svg+xml",
  CSV_MIME_TYPE = "text/csv"
}
