import { AnyObject } from "../../../global";

export const ConsoleRouteMap = {
  HOME: {
    path: "/",
    screenNameKey: "",
    clientList: {
      path: "clients-list",
      screenNameKey: "",
      create: {
        path: "create"
      },
      detail: {
        path: ":id"
      }
    },
    userList: {
      path: "users",
      screenNameKey: "",
      create: {
        path: "create"
      },
      detail: {
        path: ":consoleUserId"
      }
    },
    adminActions: {
      path: "admin-actions",
      scheduledJobs: {
        path: "scheduled-jobs",
        detail: {
          path: ":id"
        }
      }
    },
    configuration: {
      path: "configurations",
      screenNameKey: "",
      email: {
        path: "email"
      },
      emailTemplate: {
        path: "emailTemplate"
      },
      emailSnippet: {
        path: "emailSnippet"
      }
    }
  },
  CLIENT: {
    path: "/client",
    screenNameKey: "",
    eventSeriesList: {
      path: "event-series-list",
      screenNameKey: "",
      create: {
        path: "create"
      },
      detail: {
        path: ":eventSeriesId"
      }
    },
    CONFIGURATIONS: {
      path: "configuration",
      UPDATE_CLIENT: {
        path: "update-client"
      }
    }
  },
  EVENT_SERIES: {
    path: "/event-series",
    screenNameKey: "",
    ORG_LIST: {
      path: "organization-list",
      screenNameKey: "organizations",
      create: {
        path: "create"
      },
      detail: {
        path: ":organizationId"
      }
    },
    registrationsList: {
      path: "registrations-list",
      screenNameKey: "",
      create: {
        path: "create"
      },
      detail: {
        path: ":registrationId"
      }
    },
    userList: {
      path: "users",
      screenNameKey: "",
      create: {
        path: "create"
      },
      detail: {
        path: ":consoleUserId"
      }
    },
    configurations: {
      path: "configuration",
      UPDATE_EVENT_SERIES: {
        path: "update"
      },
      EMAIL: {
        path: "email-configuration"
      }
    }
  },
  REGISTRATION: {
    path: "/registration",
    screenNameKey: "",
    dashboard: {
      path: "dashboard",
      screenNameKey: ""
    },
    APPROVAL: {
      path: "approval",
      screenNameKey: "",
      PAGINATED: { path: "paginated-approval" },
      CREATE: {
        path: "create"
      },
      DETAIL: {
        path: ":registrationParticipantId"
      }
    },
    ORGANIZATION: {
      path: "organization",
      screenNameKey: "registration.organization",
      CREATE: {
        path: "create"
      },
      DETAIL: {
        path: ":registrationOrganizationId"
      }
    },
    REPORTS: {
      path: "reports"
    },
    SEARCH_INVOICE: {
      path: "searchInvoice",
      PARTICIPANT_DETAILS: {
        path: ":registrationParticipantId"
      }
    },
    CONFIGURATIONS: {
      path: "configuration",
      REGISTRATION: {
        path: "registration"
      },
      DIVISIONS: {
        path: "division",
        screenNameKey: "divisions",
        CREATE: {
          path: "create"
        },
        DETAIL: {
          path: ":divisionId"
        }
      },
      EMAIL_CONFIG: {
        path: "email-config"
      },
      SOCIAL_MEDIA_CONFIG: {
        path: "social-media-config",
        screenNameKey: "socialMediaConfig",
        CREATE: {
          path: "create"
        },
        DETAIL: {
          path: ":socialMediaTemplateId"
        }
      },
      MOBILE_APP_CONFIG: {
        path: "mobile-app",
        screenNameKey: "mobileAppConfig",
        CONFIGURATION: {
          path: "mobileConfig",
          GENERAL: {
            path: "general"
          },
          NOTIFICATION: {
            IOS: {
              path: "ios"
            },
            ANDROID: {
              path: "android"
            }
          },
          CHAT: {
            path: "chat"
          }
        },
        TEMPLATES: {
          path: "mobileTemplates",
          DETAIL: {
            path: ":templateId",
            PREVIEW: {
              path: "preview"
            }
          }
        },
        NOTIFICATION: {
          path: "mobileNotification",
          COMPOSE: {
            path: "compose"
          },
          DETAIL: {
            path: ":id"
          }
        }
      },
      SMS_CONFIG: {
        path: "sms",
        screenNameKey: "smsConfig",
        CONFIGURATION: {
          path: "smsConfig",
          GENERAL: {
            path: "general"
          },
          NOTIFICATION: {
            IOS: {
              path: "ios"
            },
            ANDROID: {
              path: "android"
            }
          }
        },
        TEMPLATES: {
          path: "smsTemplates",
          DETAIL: {
            path: ":templateId",
            PREVIEW: {
              path: "preview"
            }
          }
        },
        COMPOSE: {
          path: "smsCompose",
          SEND_NOW: {
            path: "send-now"
          }
        }
      },
      PAYMENT_CONFIG: {
        path: "payment-config",
        screenNameKey: "",
        CREATE: {
          path: "create"
        },
        DETAIL: {
          path: ":paymentConfigId"
        }
      },
      RPT: {
        path: "rpt",
        screenNameKey: "rpt",
        CREATE: {
          path: "create"
        },
        DETAIL: {
          path: ":rptId"
        }
      },
      RATE_CARD: {
        path: "rate-card",
        CREATE: {
          path: "create/:inventoryType"
        },
        DETAIL: {
          path: ":rateCardId"
        }
      },
      FORM_FIELDS: {
        path: "form-fields",
        CREATE: {
          path: "create"
        },
        DETAIL: {
          path: ":schemaFieldId"
        }
      },
      EVENTS: {
        path: "events",
        CREATE: {
          path: "create"
        },
        DETAIL: {
          path: ":eventId"
        }
      },
      PROGRAM: {
        path: "program",
        CREATE: {
          path: "create"
        },
        DETAIL: {
          path: ":programId"
        }
      },
      EMAIL_CONFIGURATIONS: {
        path: "email",
        EMAIL_CONFIG: {
          path: "emailConfig"
        },
        EMAIL_TEMPLATES: {
          path: "emailTemplates"
        },
        EMAIL_SNIPPET: {
          path: "emailSnippet"
        }
      },
      FAQ_CONFIGURATIONS: {
        path: "faq",
        ADD_MODULE: {
          path: "add"
        },
        UPDATE_CONTENT: {
          path: ":moduleId"
        }
      },
      EVENT_TRIGGER_CONFIG: {
        path: "event-trigger",
        UPDATE_CONTENT: {
          path: ":moduleId"
        }
      },
      DISPLAY_NOTES: {
        path: "displayNotes",
        detail: {
          path: ":displayNoteId"
        }
      },
      INVENTORY_CONFIGURATION: {
        path: "inventory",
        SETTINGS: {
          path: "settings",
          DETAIL: {
            path: ":inventoryId"
          }
        },
        REG_FORMS: {
          path: "forms",
          screenNameKey: "",
          CREATE: {
            path: "create"
          },
          DETAIL: {
            path: ":formId",
            CREATE_FIELD: {
              path: "create"
            }
          }
        },
        MEETING: {
          path: "meeting",
          CREATE: {
            path: "create"
          },
          DETAIL: {
            path: ":meetingFormId"
          }
        }
      },
      EMAIL_DOMAIN: {
        path: "email-domain-list",
        CREATE: {
          path: "create"
        },
        UPDATE: {
          path: ":emailDomainListId"
        }
      }
    },
    EVENT: {
      path: "event",
      agendaMembers: {
        path: "agenda-members",
        detail: {
          path: ":eventParticipantId"
        }
      },
      AGENDA_CONFIGURATION: {
        path: "agenda",
        CREATE: {
          path: "create"
        },
        DETAIL: {
          path: ":agendaItemId"
        },
        TIME_SESSION: {
          path: "timeSession"
        },
        TIME_SLOT: {
          path: "timeSlot"
        },
        ATTRIBUTES: {
          path: "attributes",
          CREATE: {
            path: "create"
          },
          DETAIL: {
            path: ":agendaAttributeId"
          }
        },
        PREVIEW: {
          path: "preview"
        }
      },
      sponsorOrganization: {
        path: "sponsorOrg",
        create: {
          path: "create"
        },
        detail: {
          path: ":sponsorOrganizationId"
        }
      },
      program: {
        path: "program",
        detail: {
          path: ":programId"
        }
      },
      configuration: {
        path: "eventConfiguration",
        eventSettings: {
          path: "details"
        },
        TIMELINES: {
          path: "timelines",
          details: {
            path: ":eventStageId"
          }
        },
        sponsorshipLevels: {
          path: "eventLevels",
          create: {
            path: "create"
          },
          detail: {
            path: ":levelId"
          }
        },
        FEEDBACK: {
          path: "feedback",
          create: {
            path: "create"
          },
          detail: {
            path: ":feedbackFormId",
            CREATE_FIELD: {
              path: "create"
            }
          }
        },
        LOCATIONS: {
          path: "locations",
          CREATE: {
            path: "create"
          },
          DETAILS: {
            path: ":locationId"
          },
          SECTORS: {
            path: "sectors",
            CREATE: {
              path: "create"
            },
            DETAILS: {
              path: ":eventSectorId"
            }
          }
        },
        VENDOR: {
          path: "vendor",
          CREATE_VENDOR: {
            path: "create-vendor"
          },
          CREATE: {
            path: "create"
          },
          DETAILS: {
            path: ":vendorConfigurationId"
          },
          ENTITY: {
            path: "entity-configuration"
          }
        }
      }
    }
  },
  PROGRAM: {
    path: "/program",
    ORGANIZATION: {
      path: "organization",
      ADD_MEETING: { path: "add-meeting" },
      PURCHASE_DETAILS: { path: "purchase-details" },
      SPONSORSHIPS: { path: "sponsorships" },
      MANAGE_SPONSORSHIPS: { path: "manage-sponsorships" },
      ADD_MEETING_To_PROGRAMORG: { path: "add-meeting/:programOrganizationId" },
      PURCHASE_DETAILS_To_PROGRAMORG: { path: "purchase-details/:programOrganizationId" },
      SPONSORSHIPS_To_PROGRAMORG: { path: "sponsorships/:programOrganizationId" },
      MANAGE_SPONSORSHIPS_To_PROGRAMORG: { path: "manage-sponsorships/:programOrganizationId" }
    },
    dashboard: {
      path: "dashboard"
    },
    WORKFLOW: {
      path: "workflow",
      ENROLLMENT: {
        path: "enrollment",
        DETAIL: {
          path: ":registrationParticipantId"
        },
        SEARCH_CRITERIA: {
          path: "searchBy"
        }
      },
      PROFILE: {
        path: "profile",
        PARTICIPANT: {
          path: "participant",
          detail: {
            path: ":programParticipantId"
          }
        },
        ORGANIZATION: {
          path: "organization",
          detail: {
            path: ":programOrganizationId"
          }
        }
      },
      SELECTION_ROUND_1: {
        path: "selection-round-1",
        detail: {
          path: ":senderId"
        }
      },
      SELECTION_ROUND_2: {
        path: "selection-round-2",
        detail: {
          path: ":senderId"
        }
      },
      SELECTION_ROUND_3: {
        path: "selection-round-3",
        detail: {
          path: ":senderId"
        }
      },
      MEETING: {
        path: "meeting"
      },
      FEEDBACK: {
        path: "feedback"
      },
      REIMBURSEMENT: {
        path: "reimbursement",
        detail: {
          path: ":reimbursementParticipantId"
        }
      },
      TABLETALK_ENROLLMENT: {
        path: "tabletalk-enrollment",
        DETAIL: {
          path: ":registrationParticipantId"
        },
        SEARCH_CRITERIA: {
          path: "searchBy"
        }
      },
      TABLETALK_PROFILE: {
        path: "tabletalk-profile",
        detail: {
          path: ":programParticipantId"
        }
      },
      TABLETALK_MEETING: {
        path: "tabletalk-meeting",
        detail: {
          path: ":meetingId"
        }
      },
      TABLETALK_ACCEPT_MEETINGS: {
        path: "tabletalk-invitation"
      },
      TABLETALK_FEEDBACK: {
        path: "tabletalk-feedback"
      },
      ASSOCIATED_PROGRAM: {
        path: "",
        PROFILE: {
          ACTIVITY_CONFIG: {
            path: "ap-activity",
            CREATE: {
              path: "create"
            },
            DETAIL: {
              path: ":activityId"
            },
            TIMESLOTS: {
              path: "timeslot"
            },
            ACTIVITY_TIMESLOTS: {
              path: "timeslots/:activityId"
            }
          }
        },
        REQUEST: {
          path: "ap-request"
        },
        SHORTLIST: {
          DETAIL: { path: ":activityId" },
          path: "ap-shortlist"
        },
        SCHEDULE: {
          DETAIL: { path: ":activityAgendaId" },
          path: "ap-schedule"
        },
        ATTENDANCE: {
          DETAIL: { path: ":activityAgendaId" },
          path: "ap-attendance"
        }
      }
    },

    CONFIGURATIONS: {
      path: "configuration",
      UPDATE_PROGRAM: {
        path: "update"
      },
      MEETUP_PROFILE: {
        path: "meetupProfile",
        CREATE: {
          path: "create",
          CREATE_FIELD: {
            path: "create"
          }
        },
        DETAIL: {
          path: ":meetupProfileFormId",
          CREATE_FIELD: {
            path: "create"
          }
        }
      },
      TABLETALK_TOPIC: {
        path: "topic",
        create: {
          path: "create"
        },
        detail: {
          path: ":topicId"
        }
      },
      TIMELINES: {
        path: "timelines",
        details: {
          path: ":programStageId"
        }
      },
      cardConfig: {
        path: "card",
        create: { path: "create" },
        details: {
          path: ":cardId"
        }
      },
      featuredLists: {
        EXHIBITOR: {
          path: "featured-list-exhibitor",
          create: { path: "create" },
          details: {
            path: ":fslId"
          }
        },
        OPTIN: {
          path: "featured-list-optin",
          create: { path: "create" },
          details: {
            path: ":fslId"
          }
        },
        path: "featured-list",
        create: { path: "create" },
        details: {
          path: ":fslId"
        }
      }
    }
  },
  LOGIN: {
    path: "login",
    screenNameKey: ""
  },
  RESET_PASSWORD: {
    path: "reset-password",
    screenNameKey: ""
  },
  FORGOT_PASSWORD: {
    path: "forgot-password",
    screenNameKey: ""
  },
  ACCEPT_USER_INVITE: {
    path: "accept-invite",
    screenNameKey: ""
  },
  ACCEPT_USER_ROLE: {
    path: "accept-role",
    screenNameKey: ""
  },
  IFRAME: {
    path: "iframe",
    screenNameKey: "",
    EVENT_AGENDA: {
      path: "event-agenda",
      screenNameKey: ""
    },
    SPONSOR_ORGANIZATION: {
      path: "sponsor-org"
    }
  }
};

export function getAbsolutePath(pathNamespace: string) {
  if (!pathNamespace) {
    return "";
  }
  const pathArr = pathNamespace.split(".");
  let absolutePath = "";
  let objToLook: AnyObject = { ConsoleRouteMap };
  pathArr.forEach(item => {
    if (objToLook[item]?.path) {
      if (!objToLook[item]?.path.startsWith("/")) {
        absolutePath += "/";
      }
      absolutePath += objToLook[item]?.path;
    }
    objToLook = objToLook[item];
  });

  return absolutePath;
}
