import { Environment, AppType } from "./enums";
import { AppTypeAndUrl, EnvironmentInfo } from "./utils.d";

let info: EnvironmentInfo;

export function getEnvironment() {
  return process.env.REACT_APP_CUSTOM_ENV;
}

function getItemsFromFirstSubdomain() {
  const [subdomain] = window.location.hostname.split(".");
  return subdomain.split("-");
}

// URLs expected to be in following format
// console.personatech.com
// myexperience-regkey.personatech.com
// registration-regkey.personatech.com
function getAppType() {
  const itemsInSubdomain = getItemsFromFirstSubdomain();
  return itemsInSubdomain[0].toUpperCase() === AppType.CONSOLE ? AppType.CONSOLE : itemsInSubdomain[1]?.toUpperCase();
}

export function getEnvironmentTypeFromUrl() {
  const itemsInSubdomain = getItemsFromFirstSubdomain();
  return itemsInSubdomain[0].toUpperCase() === AppType.CONSOLE ? itemsInSubdomain[1] : itemsInSubdomain[2];
}

function getKeyFromUrl() {
  const [regKey] = getItemsFromFirstSubdomain();
  return regKey;
}

function getApiUrl() {
  return `${getUrl()}/api`;
}

function getUrl() {
  return `https://${window.location.hostname}`;
}

function getLocalAppType() {
  switch (window.location.hostname) {
    case "localhost":
    case "myexp":
      return {
        appType: AppType.MYEXPERIENCE
      };
    case "127.0.0.1":
      return {
        appType: AppType.CONSOLE
      };
    case "127.0.0.2":
    case "reg":
      return {
        appType: AppType.REGISTRATION
      };
    default:
      return { appType: AppType.CONSOLE };
  }
}

function getLocalAppTypeByPort(): AppTypeAndUrl {
  switch (window.location.port) {
    case "4000":
      return {
        appType: AppType.CONSOLE,
        url: process.env.REACT_APP_LOCAL_CONSOLE_URL || ""
      };
    case "4001":
      return {
        appType: AppType.MYEXPERIENCE,
        url: process.env.REACT_APP_LOCAL_MYEXP_URL || ""
      };
    case "4002":
      return {
        appType: AppType.REGISTRATION,
        url: process.env.REACT_APP_LOCAL_REGISTRAION_URL || ""
      };

    default:
      return { appType: AppType.CONSOLE, url: process.env.REACT_APP_LOCAL_CONSOLE_URL || "" };
  }
}

export function getEnvironmentInfo() {
  if (info) {
    return info;
  }
  switch (getEnvironment()) {
    //Local build is for QA/Testing etc.
    case Environment.LOCAL:
    case Environment.LOCAL_BUILD:
    case Environment.TEST:
    case Environment.WRITING_TEST_CASES:
      info = {
        ...getLocalAppType(),
        url: window.location.origin,
        apiUrl: process.env.REACT_APP_LOCAL_API_URL || ""
      };
      return info;

    case Environment.LOCAL_BUILD_LOCAL_HOST_ONLY:
      info = {
        ...getLocalAppTypeByPort(),
        apiUrl: process.env.REACT_APP_LOCAL_API_URL || ""
      };
      return info;

    // this can be seprated if we need something more specific than apptypes only
    case Environment.QA:
    case Environment.STAGING:
    case Environment.DEMO:
    case Environment.FEATURE:
      info = {
        appType: getAppType(),
        url: getUrl(),
        apiUrl: getApiUrl()
      };
      return info;
    case Environment.PRODUCTION:
      info = {
        appType: getAppType(),
        url: getUrl(),
        apiUrl: getApiUrl()
      };
      return info;

    default:
      throw new Error(`Environment ${getEnvironment()} is not supported`);
  }
}

export const isConsole = getEnvironmentInfo().appType === AppType.CONSOLE;

export const isRegPortal = getEnvironmentInfo().appType === AppType.REGISTRATION;

/**
 * For local build returns registration key from REACT_APP_LOCAL_API_URL environment variable
 * For production/staging etc returns registraion key from url
 * @returns registration key for static data
 */
export function getRegistrationKey(): string {
  switch (getEnvironment()) {
    case Environment.LOCAL:
    case Environment.LOCAL_BUILD:
    case Environment.TEST:
    case Environment.LOCAL_BUILD_LOCAL_HOST_ONLY:
    case Environment.WRITING_TEST_CASES:
      return process.env.REACT_APP_LOCAL_REGISTRATION_KEY || "";

    case Environment.QA:
    case Environment.STAGING:
    case Environment.DEMO:
    case Environment.PRODUCTION:
    case Environment.FEATURE:
      return getAppType() === AppType.CONSOLE ? "" : getKeyFromUrl();

    default:
      throw new Error(`Environment ${getEnvironment()} is not supported`);
  }
}
