import MemoryStorage from "./MemoryStorage";
import { LocalStorageKey } from "../enums";

export default class LocalStorage {
  static getItem(key: LocalStorageKey) {
    try {
      const val = localStorage.getItem(key);
      return val || MemoryStorage.getItem(key);
    } catch (e) {
      return MemoryStorage.getItem(key);
    }
  }

  static setItem(key: LocalStorageKey, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      MemoryStorage.setItem(key, value);
    }
  }

  static removeItem(key: LocalStorageKey) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      MemoryStorage.removeItem(key);
    }
  }
}
