export enum Route {
  REGISTRATION_FORM = "regForm",
  REGISTRATION_PAYMENT = "regPayment",
  LOGIN = "login",
  SHARE_ON_SOCIAL_MEDIA = "socialMediaPostRoute",
  PROFILE = "profile",
  STRIPE_STATUS = "payStatus",
  AGENDA_PARTICIPATION_INVITE = "agendaParticipationInvite"
}

export enum RedirectQueryParam {
  ROUTE = "route",
  TOKEN = "token",
  REGISTRATION_PARTICIPANT_ID = "rpId",
  PROGRAM_TYPE = "pT",
  PROGRAM_PARTICIPANT_ID = "ppId",
  STRIPE_PAYMENT_INTENT_ID = "payment_intent",
  STRIPE_REDIRECT_STATUS = "redirect_status",
  STRIPE_PAYMENT_CLIENT_SECRET = "payment_intent_client_secret",
  STRIPE_SOURCE_TYPE = "source_type",
  PURCHASE_ORDER_ID = "poId",
  AMOUNT_OBJECT = "amObj",
  REG_PARTICIPAN_UI_VERSION = "rpUiV",
  STATUS = "status",
  INVOICE_PAYMENT_REQUEST_ID = "iprId",
  INVOICE_PAYER_ID = "ipId"
}
