import { LoaderProps } from "./loaderTypes";
import classNames from "classnames";
import Icon from "assets/icons";

function Loader({ inPlace = false, ...props }: LoaderProps) {
  return (
    <div className={classNames("loader_wrapper", { "loader--inPlace": inPlace })}>
      <div className="loader">
        <Icon.LoaderCirculer className="loaderIcon" />
        {props.loadingMessage && <p>{props.loadingMessage}</p>}
      </div>
    </div>
  );
}

export default Loader;
