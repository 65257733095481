import React from "react";
import { AppType } from "./shared/utils/enums";
import { getEnvironmentInfo } from "./shared/utils/config";
import FullPageLoaderWithPortal from "shared/components/loader";
import lazyLoad from "shared/utils/lazyLoad";
import { isInIframe } from "shared/utils/iframe";
const Console = lazyLoad(() => import("./apps/console"));
const MyExperience = lazyLoad(() => import("./apps/myExperience"));
const Registration = lazyLoad(() => import("./apps/registration"));

function getApp() {
  switch (getEnvironmentInfo().appType) {
    case AppType.CONSOLE:
      document.body.classList.add("appType--admin");
      if (isInIframe) {
        document.body.classList.add("bodyBgTransparent");
      }
      return <Console />;

    case AppType.MYEXPERIENCE:
      document.body.classList.add("appType--reg");
      return <MyExperience />;

    case AppType.REGISTRATION:
      document.body.classList.add("appType--reg");
      if (isInIframe) {
        document.body.classList.add("bodyBgTransparent");
      }
      return <Registration />;

    default:
      return <div>No valid App Type Found</div>;
  }
}

function App() {
  return <React.Suspense fallback={<FullPageLoaderWithPortal isLoading />}>{getApp()}</React.Suspense>;
}

export default App;
