import React from "react";
import ChunkReload from "shared/components/chunkReload";

type ComponentPromise<T = any> = Promise<{ default: React.ComponentType<T> }>;

/**
 * Lazy Loads a component, if chunk load fails then redirects to home page
 */
export default function lazyLoad(
  component: () => ComponentPromise
): React.LazyExoticComponent<React.ComponentType<any>> {
  return React.lazy(() => retry(component));
}

function retry(fn: () => ComponentPromise): ComponentPromise {
  return new Promise(resolve => {
    fn()
      .then(resolve)
      .catch(() => {
        resolve({
          default: ChunkReload
        });
        return;
      });
  });
}
