import React from "react";
import "./toast.scss";
import Icon from "assets/icons";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastOptions } from "react-toastify";
import { TOAST_NOTIFICATION_AUTO_CLOSE_TIME } from "../constants";

export const toastConfig = {
  icon: true,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: TOAST_NOTIFICATION_AUTO_CLOSE_TIME
};

export function toastMessage(message: React.ReactNode, options?: ToastOptions) {
  return toast(message, {
    type: toast.TYPE.INFO,
    ...options
  });
}
/**
 * Info Toast message
 * @see https://fkhadra.github.io/react-toastify/api/toast
 */
export function infoToast(message: React.ReactNode, options?: ToastOptions) {
  const toastIcon = toastConfig.icon ? <Icon.InfoCircle /> : true;
  return toast.info(message, (options = { ...options, icon: toastIcon }));
}

/**
 * Success Toast message
 * @see https://fkhadra.github.io/react-toastify/api/toast
 */
export function successToast(message: React.ReactNode, options?: ToastOptions) {
  const toastIcon = toastConfig?.icon ? <Icon.CheckCircle /> : true;
  return toast.success(message, (options = { ...options, icon: toastIcon }));
}

/**
 * Error Toast message
 * @see https://fkhadra.github.io/react-toastify/api/toast
 */
export function errorToast(message: React.ReactNode, options?: ToastOptions) {
  const toastIcon = options?.icon ? <Icon.Block /> : true;
  return toast.error(message, (options = { ...options, icon: toastIcon }));
}

/**
 * Warning Toast message
 * @see https://fkhadra.github.io/react-toastify/api/toast
 */
export function warningToast(message: React.ReactNode, options?: ToastOptions) {
  const toastIcon = toastConfig?.icon ? <Icon.Warning /> : true;
  return toast.warn(message, (options = { ...options, icon: toastIcon }));
}

/**
 * To dismiss all toast don't pass any thing, To dismiss specific toast pass id
 * @see https://fkhadra.github.io/react-toastify/api/toast
 */
export function dismissToast(id?: React.ReactText) {
  toast.dismiss(id);
}

/**
 * To check if toast is active
 * @see https://fkhadra.github.io/react-toastify/prevent-duplicate/#check-if-a-toast-is-already-displayed
 */
export function isToastActive(toastId: string) {
  return toast.isActive(toastId);
}
