import { AnyObject } from "../../../global";

export default class MemoryStorage {
  static dataStorage: AnyObject = {};

  static getItem(key: string) {
    return this.dataStorage[key];
  }

  static setItem(key: string, value: string) {
    this.dataStorage[key] = value;
  }

  static removeItem(key: string) {
    delete this.dataStorage[key];
  }
}
